import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
//   constructor(
//     private sanitizer: DomSanitizer,
//   ) { }

    transform(value: string, limit: number = 20): string {
        if (!value) return '';
        return value.length > limit ? value.substring(0, limit) + '...' : value;
    }

}
