import { Component, Inject, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AppURL } from 'src/app/app.url';
import { Inbox } from 'src/app/interfaces/inbox';
import { NavbarNamespace } from 'src/app/interfaces/navbar.interface';
import { Reply } from 'src/app/interfaces/reply';
import { KEY_TOKEN, KEY_USER } from 'src/app/models/app.models';
import { AccountUrl } from 'src/app/modules/account/account.url';
import { AuctionService } from 'src/app/services/auction.service';
import { CustomerService } from 'src/app/services/customer.service';
import { InboxService } from 'src/app/services/inbox.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ShopService } from 'src/app/services/shop.service';
import { UserService } from 'src/app/services/user.service';
import { ModalLoginComponent } from 'src/app/shared/components/modal-login/modal-login.component';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { OrderService } from 'src/app/shared/services/order/order.service';
import { Logger } from 'src/app/utils/console';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { resolve } from 'url';
import { AuthenticationService } from '../../services/authentication.service';
import { LocalStorageService } from '../../services/local-storage.service';
import moment from 'moment';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { Location } from '@angular/common';


@Component({
  selector: 'best-navbar',
  templateUrl: './best-navbar.component.html',
  styleUrls: ['./best-navbar.component.scss'],
  providers: [LanguagePipe]
})
export class BestNavbarComponent implements OnInit, OnDestroy {

  /** เมนู */
  menuItems: NavbarNamespace.IMenu[] = [
    {
      name: 'ซื้อ',
      link: ['/'],
      exact: true,
      matchUrlLists: [
        AppURL.advanceSearch,
        // AppURL.productDetail
      ]
    },
    { name: 'ขาย', link: ['/', AppURL.sale], exact: true },
    {
      name: 'ประมูล',
      link: ['/'],
      matchUrlLists: [
        '/auction-more'
      ],
      exact: true
    },
    { name: 'ร้านค้า', link: ['/', AppURL.shop], exact: false },
    { name: 'ร้านค้า', link: ['/'], exact: false },
    { name: 'ราคา', link: ['/'], exact: false },
    // { name: 'เกี่ยวกับ', link: ['/', AppURL.about], exact: true },
  ];

  @ViewChild('loginModal', { static: true }) loginModal: TemplateRef<any>;
  @ViewChild('rankModal', { static: true }) rankModal: TemplateRef<any>;
  @ViewChild('lossModal', { static: true }) lossModal: TemplateRef<any>;

  @ViewChild('verifyModal', { static: true }) verifyModal: TemplateRef<any>;
  modalRefVerify: BsModalRef;

  api = environment.api;

  modalRefLogin: BsModalRef;
  modalRefRank: BsModalRef;
  modalRefLoss: BsModalRef;

  routerActive = '';

  CountinCart = 0;

  notificationHub: any;
  notiData: any;
  notiItems: any[];
  notifyCount: number;

  auctionHub: any;
  auctionStared: any;
  // notificationCustomerHub: any

  customer: ICustomer;
  Subscriptions: Subscription[] = [];

  shopName: string = '';
  urlUse: string = '';

  languageItems: {id: string, src: string, name: string}[] = [
    { id: 'lc', src: './assets/icons/th-icon.png', name: 'ภาษาไทย' },
    { id: 'en', src: './assets/icons/eng-icon.png', name: 'english' },
  ];
  selectedLanguage: {id: string, src: string, name: string} = { id: 'lc', src: './assets/icons/th-icon.png', name: 'ภาษาไทย' };

  urlImage: string;
  imageBase = environment.content;
  unshowUserMode: boolean = false;
  shop: any;
  checkShop: boolean = false;
  dVerifyUser: boolean = false;
  alertVerifyUser: boolean = false;

  dropdownItem: any;
  urlShop: string = '';
  noUserImg = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8Cuyq7AdKhhmAL6ceV-s8oLT630oo9uMOWPjH994GigCH5S3q';
  nullImageShop: string = `assets/svg/store-solid.svg`;
  appURL = AppURL;
  onNotify = false;
  environmentData = environment;
  tabHidden: boolean = null;
  startHiddenTab: boolean = false;
  selectLange: string = 'lc';

  constructor(
    private modalService: BsModalService,
    private storage: LocalStorageService,
    private appService: AppService,
    private auth: AuthenticationService,
    private loginService: LoginService,
    @Inject(WINDOW) private window: Window,
    private userSv: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public shopService: ShopService,
    private orderService: OrderService,
    private notiSv: NotificationService,
    private auctionSv: AuctionService,
    private svCustomer: CustomerService,
    private svInbox: InboxService,
    private languageSer: LanguagePipe,
    private _location: Location,
  ) {
    let lange = localStorage.getItem('language');
    if (lange) {
      this.selectLange = lange;
      this.selectedLanguage = this.languageItems[0];
      if (lange !== 'lc') {
        this.selectedLanguage = this.languageItems[1];
      }
    } else {
      this.languageUse(this.selectLange);
    }

    let url = this.router.url;
    let listUrl = url.split('/');
    if (listUrl.length >= 2) {
      this.unshowUserMode = true;
    } else {
      this.unshowUserMode = false;
    }

    if (listUrl.length === 3 && listUrl[1] === "shop") {
      this.checkShop = true;
      this.urlShop = decodeURI(listUrl[2]);
      this.setDropdownItem(this.urlShop);
    } else {
      this.checkShop = false;
      this.setDropdownItem('');
    }
    
    this.Subscriptions.push(
      this.svCustomer.Customer$.subscribe(customer => {
        this.customer = customer;
        this.verifyCustomerStatus();
        if (this.customer && this.customer.ProfileImg !== null && this.customer.ProfileImg !== '') {
          this.urlImage = `${this.imageBase + this.customer.ProfileImg}`;
        }

        let url = this.router.url.split('/');
        if (url[1] === this.appURL.notification) {
          this.onNotify = true;
        }
      }),
      this.svCustomer.updateCustomer$.subscribe(local => {
        if (!local) {
          url = this.router.url;
          listUrl = url.split('/');
          if (listUrl.length >= 3 && (listUrl[1] === AppURL.shop ||
              listUrl[1] === AppURL.auctionDetail ||
              listUrl[1] === AppURL.managements)) {
            this.urlShop = decodeURI(listUrl[2]);
          }
          if (this.urlShop) {
            this.shopService.customerInfo(this.urlShop).subscribe((customer: any) => {
              this.svCustomer.Customer$.next(customer);
            });
          } else {
            this.svCustomer.getCustomerInfo().subscribe(customer => {
              this.svCustomer.Customer$.next(customer);
            });
          }

        } else {
          this.svCustomer.Customer$.next(this.storage.get(KEY_USER));
        }
      }),
      this.appService.subject.asObservable().subscribe(res => {
        if (res) {
          if (res.statusLogin) {
            return this.openModalLogin();
          }
          if (!res.statusLogin) {
            return this.openModalRegister();
          }
          if (res.rankWinner === true) {
            return this.openModalRank();
          }
        }
      }),
      this.appService.login.subscribe(res => {
        if (res) {
          this.openModalLogin();
        }
      }),
      this.userSv.winner.subscribe(res => {
        if (!res) {
          return;
        }
        this.openModalRank();
      }),
      this.userSv.loser.subscribe(res => {
        if (!res) {
          return;
        }
        this.openModalLoss();
      }),
      this.appService.activeRouter.subscribe(res => {
        this.urlUse = this.router.url;
        this.routerActive = res;
      }),
      this.notiSv.notiItems$.subscribe(res => {
        this.notiData = res;
        this.notiItems = [];
        if (res) {
          res.items.forEach((item: any, index: number) => {
            if (item.CreatedAt) {
              item['settime'] = moment(item.CreatedAt).format('YYYYMMDDHHmm');
              item.settime = moment(item.settime, "YYYYMMDDHHmm").fromNow();
              if (this.selectLange === 'lc') {
                let checkText = false;
                if (item.settime === 'a few seconds ago') { 
                  item.settime = 'ไม่กี่วินาทีผ่านมา';
                } else {
                  let listTime = item.settime.split(" ");
                  let settext = listTime[0] !== 'an' && listTime[0] !== 'a' ? listTime[0] : 'ไม่กี่';
                  if (listTime[1] === 'seconds' || listTime[1] === 'seconds') {
                    checkText = true;
                    settext += ' วินาที ผ่านมา';
                  } else if (listTime[1] === 'minute' || listTime[1] === 'minutes') {
                    checkText = true;
                    settext += ' นาที ผ่านมา';
                  } else if (listTime[1] === 'hour' || listTime[1] === 'hours') {
                    checkText = true;
                    settext += ' ชม. ผ่านมา';
                  } else if (listTime[1] === 'day' || listTime[1] === 'days') {
                    checkText = true;
                    settext += ' วัน ผ่านมา';
                  } else if (listTime[1] === 'month' || listTime[1] === 'months') {
                    checkText = true;
                    settext += ' เดือน ผ่านมา';
                  } else if (listTime[1] === 'year' || listTime[1] === 'years') {
                    checkText = true;
                    settext += ' ปี ผ่านมา';
                  }
                  if (checkText) {
                    item.settime = settext;
                  }
                }
              } else {
                let checkText = false;
                if (item.settime === 'ไม่กี่วินาทีผ่านมา') { 
                  item.settime = 'a few seconds ago';
                } else {
                  let listTime = item.settime.split(" ");
                  let settext = listTime[0] !== 'ไม่กี่' ? listTime[0] : 'a';
                  if (listTime[1] === 'วินาทีที่แล้ว') {
                    checkText = true;
                    settext += ' seconds';
                  } else if (listTime[1] === 'นาทีที่แล้ว') {
                    checkText = true;
                    settext += ' minutes';
                  } else if (listTime[1] === 'ชั่วโมงที่แล้ว') {
                    checkText = true;
                    settext += ' hours';
                  } else if (listTime[1] === 'วันที่แล้ว') {
                    checkText = true;
                    settext += ' days';
                  } else if (listTime[1] === 'เดือนที่แล้ว') {
                    checkText = true;
                    settext += ' months';
                  } else if (listTime[1] === 'ปีที่แล้ว') {
                    checkText = true;
                    settext += ' years';
                  }
                  if (checkText) {
                    item.settime = settext;
                  }
                }
              }
            }
              // this.notiItems.push(item);
            if (index < 3) {
              this.notiItems.push(item);
            }
          });
        }  
      }),
      this.appService.ResetCountCart.subscribe(res => {
        if (res) {
          this.getCountItemsOrder();
        }
      }),
      this.auctionSv.auctionStarted.subscribe(res => {
        this.auctionStared = res;
      }),
      this.appService.auctionHubStartConnect.subscribe(res => {
        let [auction, inboxs, notification] = [true, false, false];
        if (this.storage.get(KEY_TOKEN)) {
          notification = true;
          inboxs = true;
        }
        this.connectionHub(auction, inboxs, notification);
      }),
      this.shopService.store.subscribe(store => {
        if (this.router.url === '/') return;
        this.shop = store;
        if (store) { 
          this.urlShop = store.URL; 
          this.setDropdownItem(this.urlShop);
        }        
      }),
      this.shopService.alertVerifyUser.subscribe(res => {
        if (res.name === 'tabAlert') {
          this.alertVerifyUser = res.value;
        } else if (res.name === 'popupAlert') {
          this.dVerifyUser = res.value;
        } else if (res.name === 'notify') {
          this.onNotify = res.value;
        }
      })
    );
    
    if (this.appService.isBrowser) {
      $.connection.hub.url = `${this.appService.apiHost}signalr`;
      $.connection.hub.qs = {
        token: this.storage.get(KEY_TOKEN)
      };

      const paths = location.pathname.split('/');
      let [auction, inboxs, notification] = [false, false, false];
      if (paths.length > 2) {
        if (paths[1] === 'auction') {
          auction = true;
        }
      }

      if (this.storage.get(KEY_TOKEN)) {
        notification = true;
        inboxs = true;
      }
      if (!auction) {
        if (localStorage.getItem('KFA_TOKEN')) {
          this.connectionHub(auction, inboxs, notification);
        }
      }
    }

    if (this.storage.get(KEY_TOKEN)) {
      if (this.svCustomer.Customer$.value) {
        this.svCustomer.updateCustomer$.next(true);
      } else {
        this.svCustomer.updateCustomer$.next();
      }
    }

    
  }

  ngOnDestroy() {
    if (this.appService.isBrowser) {
      if (this.auctionStared && this.auctionHub && this.auctionSv.Auction) {
        if ($.connection.hub.state == $.signalR.connectionState.disconnected) return;
        this.auctionHub.server
          .leave(this.auctionSv.Auction.AucId)
          .done(() => {
            Logger('leave doned');
          })
          .fail(err => {
            Logger('leave failed', err);
          })
          .always(() => {
            $.connection.hub.stop();
          });
      } else {
        $.connection.hub.stop();
      }
    }
    this.Subscriptions.map(s => s.unsubscribe());
    this.Subscriptions = [];
  }

  ngOnInit() {
  }

  /** เปลี่ยนตัว router link กรณีที่เปิดร้านอยู่จะได้แนบบชื่อร้านเข้าไปด้วย */
  setDropdownItem(url: string) {
    if (url.length > 0) {
      this.dropdownItem = [
        {
          link: ['', 'managements', url, 'favorite'],
          class: 'far fa-heart',
          title: this.languageSer.transform('favorite')
        },
        {
          link: ['', 'managements', url, 'auctions'],
          class: 'fa fa-gavel',
          title: this.languageSer.transform('auction_items')
        },
        {
          link: ['', 'managements', url, 'profile'],
          class: 'fas fa-user-circle',
          title: this.languageSer.transform('profile')
        },
        {
          link: ['', 'managements', url, 'verify-identiry'],
          class: 'fa fa-check-square',
          title: this.languageSer.transform('verify')
        },
        {
          link: ['', 'managements', url, 'change-password'],
          class: 'fa fa-key',
          title: this.languageSer.transform('change_password')
        },
      ];
    } else {
      this.dropdownItem = [
        {
          link: ['', 'managements', 'profile'],
          class: 'fas fa-user-circle',
          title: this.languageSer.transform('profile')
        },
        {
          link: ['', 'managements', 'change-password'],
          class: 'fa fa-key',
          title: this.languageSer.transform('change_password')
        },
      ];
    }
  }

  /** ตรวจสอบ verify customer status */
  verifyCustomerStatus() {
    if (!this.customer) return;
    const { CustomerStatus } = this.customer;
    if (CustomerStatus === 'dea') {
      this.openModalLogin();
    }
  }

  /** โหลดข้อมูล ModalLoginComponent */
  onLoadModalLoginComponent(component: ModalLoginComponent) {
    if (!this.customer) return;
    const { CustomerStatus } = this.customer;
    if (CustomerStatus === 'dea') {
      this.modalRefLogin.hide();
      component.openModalVerify();
    }
  }

  /** ไปที่หน้าร้านค้า */
  onGoToStore() {
    if(!this.customer.CustomerPhoneNoVerified) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('change_password'),
        showConfirmButton: false,
        timer: 1500
      });
      this.openModalVerify();
      return;
    }
    if (this.customer.Store) return this.router.navigate(['/', 'shop', this.customer.Store]);
    this.router.navigate(['/', 'shop', 'create-shop']);
  }

  /** ตรวจสอบ active menu */
  isMenuActive(item: NavbarNamespace.IMenu) {
    let mathURL: boolean = false;
    const { matchUrlLists } = item;
    const url = this.router.serializeUrl(this.router.createUrlTree(item.link));
    if (item.exact) { mathURL = url === this.router.url; }
    else { mathURL = this.router.url.indexOf(url) >= 0; }
    if (matchUrlLists && matchUrlLists.length > 0 && !mathURL) matchUrlLists.forEach(mat => { if (!mathURL) mathURL = this.router.url.indexOf(mat) >= 0; });
    return mathURL;
  }


  openModalLogin() {
    // this.loginService.setModalPageName(0);
    // this.modalRefLogin = this.modalService.show(this.loginModal, {
    //   class: 'modal-md transparent',
    // });

    let listUrl = this.router.url.split('/');
    if (listUrl.length > 2 && listUrl[1] === 'shop' || listUrl[1] === 'auction') {
      this.appService.setURLToGo(['', 'shop', decodeURI(listUrl[2])]);
      let shopurl = decodeURI(listUrl[2]).split('?');
      this.router.navigate(['/', AppURL.account, shopurl[0], AccountUrl.signin]);
      return;
    }
    this.router.navigate(['/', AppURL.account, AccountUrl.signin]);
  }

  openModalRegister() {
    // this.loginService.setModalPageName(1);
    // this.modalRefLogin = this.modalService.show(this.loginModal, {
    //   class: 'modal-md transparent'
    // });

    let listUrl = this.router.url.split('/');
    if (listUrl.length > 2 && listUrl[1] === 'shop' || listUrl[1] === 'auction') {
      this.appService.setURLToGo(['', 'shop', decodeURI(listUrl[2])]);
      let shopurl = decodeURI(listUrl[2]).split('?');
      this.router.navigate(['/', AppURL.account, shopurl[0], AccountUrl.signup])
      return;
    }
    this.router.navigate(['/', AppURL.account, AccountUrl.signup])
  }

  openModalRank() {
    this.modalRefRank = this.modalService.show(this.rankModal, {
      class: 'modal-lg transparent mt-5'
    });
  }

  openModalLoss() {
    this.modalRefLoss = this.modalService.show(this.lossModal, {
      class: 'modal-lg transparent mt-5'
    });
  }

  closeModal() {
    if (this.modalRefRank) {
      this.modalRefRank.hide();
      this.modalRefRank = null;
    }
    if (this.modalRefLoss) {
      this.modalRefLoss.hide();
      this.modalRefLoss = null;
    }

    if (this.appService.isBrowser) {
      setTimeout(() => {
        if ($(this.window.document.body)) {
          $(this.window.document.body).removeClass('modal-open');
        }
        if ($('.modal-backdrop').length) {
          $('.modal-backdrop').remove();
        }
      }, 1000);
    }
  }

  onLogout() {
    Swal.fire({
      title: this.languageSer.transform('check_logout'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.languageSer.transform('confirm'),
      cancelButtonText: this.languageSer.transform('cancel')
    }).then(result => {
      if (result.value) {
        this.auth
          .signOut(this.storage.get(KEY_TOKEN))
          .pipe(
            finalize(() => {
              let language = localStorage.getItem('language');
              this.storage.clear();
              if (language && language === 'en') {
                localStorage.setItem('language', language);
              }
              if (this.shop) {
                this.router.navigate(['', 'shop', this.shop.URL]);
              } else {
                this.router.navigate(['']); 
              }
              this.svCustomer.Customer$.next(null);
              this.svInbox.hideMessage();

              Swal.fire({
                position: 'center',
                icon: 'success',
                title: this.languageSer.transform('success_logout'),
                showConfirmButton: false,
                timer: 1500
              });
            })
          )
          .subscribe(res => { });
      }
    });
  }

  getCountItemsOrder() {
    this.orderService.getCountItemsOrder().subscribe(res => {
      this.CountinCart = res;
    });
  }

  delay() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
  }

  async connectionHub(auction = false, inboxs = false, notification = false) {
    if (this.appService.isBrowser) {
      const max = 10;
      let a = 0;
      let iInb = 0;
      let sumting = 0;

      if (auction) {
        while (a < max) {
          await this.delay();
          if (!($.connection as any).auctionHub) {
            a++;
          } else {
            a = max;
            await this.setAuctionHub();
          }
        }
      }

      if (inboxs) {
        while (iInb < max) {
          await this.delay();
          if (!($.connection as any).inboxHub) {
            iInb++;
          } else {
            iInb = max;
            await this.setInboxHub();
          }
        }
      }

      if (notification) {
        while (sumting < max) {
          await this.delay();
          if (!($.connection as any).notificationCustomerHub) {
            sumting++;
          } else {
            sumting = max;
            await this.setNotiHub();
          }
        }
      }

      if (!auction && !inboxs && !notification) return;
      $.connection.hub.url = `${this.appService.apiHost}signalr`;
      $.connection.hub
        .start()
        .done(() => {
          Logger('hub started done');
          if (auction) {
            if (!this.auctionSv.Auction) return;
            this.auctionHub.server
              .init(this.auctionSv.Auction.AucId)
              .done(() => {
                this.auctionSv.auctionStarted.next(true);
                Logger('auction init doned');
              })
              .fail(err => Logger('auction init failed', err));
          }

          if (inboxs) {
            this.svInbox.inboxHub.server
              .init()
              .done(() => {
                Logger('inboxHub init doned');
              })
              .fail(err => Logger('inboxHub init failed', err));
          }

          if (notification) {
            this.notiSv.init(1,15,'')
              .done(() => {
                Logger('notificationCustomerHub init doned');
              })
              .fail(err => Logger('notificationCustomerHub init failed', err));
          }
        })
        .fail(err => {
          Logger('hub started error', err);
        });
    }
  }

  setAuctionHub() {
    return new Promise(resolve => {
      this.auctionHub = ($.connection as any).auctionHub;

      this.auctionHub.client.updateAuction = auction => {
        this.auctionSv.auctionSubscription.next(auction);
      };
      this.auctionHub.client.updateChat = chat => {
        this.auctionSv.chatSubscription.next(chat);
      };
      this.auctionHub.client.updateAuctionFinish = (finish: IAuction) => {
        Logger('auction finished', finish);
        this.auctionSv.finishSubscription.next(finish);
      };
      this.auctionHub.client.onCancel = canceled => {
        Logger('auction canceled');
        this.auctionSv.cancelSubscription.next(canceled);
      };

      // Lobby
      this.auctionHub.client.Accesses = (status: string) => {
        this.auctionSv.customerLobbyStatus.next(status);
      };
      this.auctionHub.client.UpdateMembersRequest = members => {
        this.auctionSv.customerLobbyRequest.next(members);
      };

      resolve(true);
    });
  }

  setInboxHub() {
    return new Promise(resolve => {
      this.svInbox.inboxHub = ($.connection as any).inboxHub;

      this.svInbox.inboxHub.client.updateInboxes = (inboxes: Inbox[]) => {
        this.svInbox.inbox$.next(inboxes);
      };
      this.svInbox.inboxHub.client.updateReplies = (replies: Reply[]) => {
        this.svInbox.replies$.next(replies);
      };
      resolve(null);
    });
  }

  setNotiHub() {
    return new Promise(resolve => {
      this.notiSv.notiHub = ($.connection as any).notificationCustomerHub;

      this.notiSv.notiHub.client.onPushNotify = (notifyItem: any) => {
        this.notiSv.notiItems$.next(notifyItem);
      };

      resolve(null);
    });
  }

  /** ไปหน้าสร้างสินค้า แต่ถ้าไม่มีร้านค้าจะไปที่หน้าสร้างร้านค้าก่อน */
  goSalePage() {
    if (this.customer.Store) {
      this.router.navigate(['/', 'shop', this.customer.Store, 'create-product']);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'info',
        toast: true,
        showConfirmButton: false,
        timer: 4000,
        text: this.languageSer.transform('กรุณาสร้างร้านค้าก่อนลงสินค้าที่คุณต้องการขาย') + ``
      });
      this.router.navigate(['/', 'shop', 'create-shop']);
    }
  }

  openModalVerify() {
    this.modalRefVerify = this.modalService.show(this.verifyModal, {
      class: 'modal-md transparent',
      ignoreBackdropClick: true
    });
  }

  /** ไปที่หน้าสร้างการประมูล */
  nextPageAuction() {
    if(!this.customer.CustomerPhoneNoVerified) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: this.languageSer.transform('havenot_verified_identity') + '\n' + this.languageSer.transform('via_phone_number'),
        showConfirmButton: false,
        timer: 1500
      });
      this.openModalVerify();
      return;
    }
    this.router.navigate(['/managements/auction']);
    // <!-- <a [routerLink]="['', 'managements', 'auction']"> -->

  }

  onCloseVerifyUser(event: any) {
    this.dVerifyUser = event.display;
    this.alertVerifyUser = (!event.save);
  }

  onPopupVerifyShop() {
    this.shopService.customerInfo(this.urlShop).subscribe(
      (res: any) => {
        if (res && res.Store_customer_verified) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: this.languageSer.transform('identity_approved'),
            showConfirmButton: false,
            timer: 1500
          });
          this.alertVerifyUser = false;
          let pageAuction =  this.router.url.split('/')[1];
          if (pageAuction === AppURL.auctionDetail) {
            this.shopService.checkVerifyUser.next(true);
          }
        } else {
          this.dVerifyUser = true;
        }
      }, err => {

      } 
    );
  }

  notifyCheck(func: string, type: string) : boolean {
    if (func === 'icon-verify') {
      return (type === 'RV' || type === 'RVS' || type === 'RVF'); 
    } else if (func === 'icon-joinAuction') {
      return ( type === 'RB' || type === 'RBS' || type === 'RBF');
    } else if (func === 'icon-auction') {
      return (type === 'AS' || type === 'AW' || type === 'AL');
    } else if (func === 'icon-store') {
      return (type === 'MVR' || type === 'MVS' || type === 'MVF');
    } else if (func === 'icon-card') {
      return (type === 'SP' || type === 'SA');
    }

    if (func === 'color-success') {
      return (type === 'RVS' || type === 'RBS' || type === 'AW' || type === 'MVS');
    } else if (func == 'color-error') {
      return (type === 'RVF' || type === 'RBF' || type === 'AL' || type === 'MVF');
    }
  }

  openpopUp() {
    if (this.notiItems && this.notiItems.length > 0) {
      this.notiSv.seen()
        .done(() => {
          Logger('seen success');
          this.notiData.UnSeen = 0;
        })
        .fail(err => Logger('notificationCustomerHub seen failed', err));
    }
  }

  nextPageNotify(index: number) {
    if (index === -1) {
      if (this.urlShop && this.urlShop.length > 1) {
        this.router.navigate(['', AppURL.notification, this.urlShop]);
      } else {
        this.router.navigate(['', AppURL.notification]);
      }
      return;
    }

    if (!(this.notiItems && this.notiItems.length > 0)) return;
    this.notiSv.read(this.notiItems[index].NotificationId)
    .done(() => {
      // Logger('read success');
      this.notiItems[index].Read = true; 
      let noti = this.notiItems[index];
      if (noti.Type === 'RV') {
        let RefCode = JSON.parse(noti.RefCode);
        this.openMerchant(`${environment.apibo}/managements/${this.customer.Store}/manage-user/profile?user=${noti.RefCode}&tab=3`);
      } else if (noti.Type === 'RVS' || noti.Type === 'RVF') { 
        this.router.navigate(['', AppURL.shop, noti.RefCode]);
      } else if (noti.Type === 'RB') { 
        let RefCode = JSON.parse(noti.RefCode);
        this.openMerchant(`${environment.apibo}/auction/${this.customer.Store}/${RefCode.auc_code}`);
      } else if (noti.Type === 'RBS' || noti.Type === 'RBF' || noti.Type === 'AS') {
        let RefCode = JSON.parse(noti.RefCode);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['', AppURL.auctionDetail, RefCode.store_url, RefCode.auc_code]);
        });
      } else if (noti.Type === 'AW' || noti.Type === 'AL') {
        let RefCode = JSON.parse(noti.RefCode);
        this.router.navigate(['', AppURL.managements, RefCode.store_url, AppURL.auctions, 'bid-auction']);
      } else if (noti.Type === 'MVR' || noti.Type === 'MVS' || noti.Type === 'MVF') {
        this.openMerchant(`${environment.apibo}/managements/${this.customer.Store}/verify-identiry`);
      } else if (noti.Type === 'SP') {
        this.openMerchant(`${environment.apibo}/managements/${this.customer.Store}/payments/package`);
      } else if (noti.Type === 'SA') {
        this.openMerchant(`${environment.apibo}/managements/${this.customer.Store}/auction/add`);
      }
    })
    .fail(err => Logger('notificationCustomerHub read failed', err));
  }

  backMainDeeAuc() {
    this.shop = null;
    this.router.navigate(['']);
  }

  openMerchant(url: string) {
    let dataToken = localStorage.getItem(KEY_TOKEN);
    let frame: any;
    frame = document.getElementById('frame');
    frame = frame.contentWindow;
    frame.postMessage({type: 'send', value: dataToken}, this.environmentData.apibo);
    window.open(url);
  }

  hiddenTab(status: boolean) {
    if (status === this.tabHidden || (!status && this.tabHidden === null && !this.startHiddenTab)) { return; }
    this.tabHidden = status;
    this.startHiddenTab = true;
  }

  languageUse(key: any) {
    if (key && key.value) {
      this.selectLange = key.value.id;
      this.appService.languageUse(key.value.id);
    } else {
      this.selectLange = key;
      this.appService.languageUse(key);
    }
  }
}