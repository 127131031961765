<div class="btn-message-container d-block d-print-none" id="messageBtn">
  <a class="btn-message" (click)="toggleMessageBox()">
    <i class="fas fa-comments"></i> Message
    <small class="btn-count-unread" [hidden]="!unreadCount">{{ unreadCount > 9 ? '9+' : unreadCount }}</small>
  </a>
</div>
<div class="message-container animated slideOutDown faster d-none" id="messageContainer">
  <div class="message-object">
    <div class="message-list">
      <div class="message-bar justify-content-center" id="msg-bar">
        <p><i class="fas fa-comments"></i> Messages</p>
      </div>
      <ul class="list-unstyled list-inbox" id="msg-box">
        <li class="list-item" *ngFor="let inb of inboxs">
          <a
            class="inbox-item"
            [class.active]="!!inboxActive && inb.CustomerId === inboxActive?.CustomerId"
            (click)="setActMsger(inb)"
          >
            <img class="img-cus" *ngIf="!!inb.CustomerImg" [src]="content + inb.CustomerImg" alt="img" />
            <img class="img-cus" *ngIf="!inb.CustomerImg" [src]="noUserImg" alt="img" />
            <div class="inbox-object">
              <p class="inbox-name">{{ inb.CustomerUsername || 'ADMIN' }}</p>
              <p class="inbox-lastmsg">
                <small>{{ inb.LastMessage }}</small>
              </p>
            </div>
            <div class="count-unread" *ngIf="!!inb.Unread">
              {{ inb.Unread > 9 ? '9+' : inb.Unread }}
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="message-box">
      <div class="message-bar border-bottom-header justify-content-between">
        <div class="">
          <!-- ADMIN -->
          <p class="mb-0" *ngIf="!!inboxActive && !inboxActive?.CustomerId">
            <img class="img-cus-bar" src="/assets/icons/technology.png" alt="img" /> ADMIN
          </p>
          <!-- OTHER CUSTOMER -->
          <p class="mb-0" *ngIf="!!inboxActive && !!inboxActive?.CustomerId">
            <img
              class="img-cus-bar"
              *ngIf="!!inboxActive?.CustomerImg"
              [src]="content + inboxActive?.CustomerImg"
              alt="img"
            />
            <img class="img-cus-bar" *ngIf="!inboxActive?.CustomerImg" [src]="noUserImg" alt="img" />




            {{ inboxActive?.CustomerUsername | truncate:20 }}
          </p>
        </div>
        <a class="d-block" (click)="toggleMessageBox()">
          <img class="btn-close" src="/assets/svg/close.svg" alt="img" width="15" />
        </a>
      </div>
      <div class="reply-scroller" id="scroll">
        <ul class="list-unstyled list-reply animated fadeIn faster" *ngIf="!!inboxActive">
          <li
            class="list-item"
            *ngFor="let rep of replies"
            [class.right]="rep.CustomerSenderId === customer?.CustomerId"
          >
            <small class="reply-newday" *ngIf="rep.newDay">{{ rep.CreateAt | date: 'd MMM' }}</small>
            <ng-container *ngIf="rep.MessageData">
              <a class="auc-compo">
                <div class="auc-image">
                  <img width="50px" [src]="content + rep.MessageData.PreviewImage[0].MedPath" alt="">
                </div>
                <div class="auc-text ml-2">
                  <p class="name">{{ rep.MessageData.AucName }}</p>
                  <p class="price" *ngIf="rep.MessageData.StartPrice && !rep.MessageData.WinnerAuctionPrice">{{'current_bid' | language}} {{ rep.MessageData.StartPrice }} ฿</p>
                  <p class="price" *ngIf="rep.MessageData.WinnerAuctionPrice">{{'current_bid' | language}} {{ rep.MessageData.WinnerAuctionPrice }} ฿</p>
                </div>
                <div class="ml-auto">
                  <button class="btn btn-primary" (click)="nextAuction(rep.MessageData)">{{'more' | language}}</button>
                </div>
              </a>
            </ng-container>
            <a class="reply-item" [class.mb-3]="rep.newTime">
              <p class="reply-text" *ngIf="!rep.MessageData">{{ rep.Message }}<span class="tringle"></span></p>
              <div>
                <p class="reply-time px-1" *ngIf="rep.newTime">{{ rep.CreateAt | date: 'HH:mm' }}</p>
                <p class="reader">{{ rep.Read  ? '✓'+ ('read' | language) : '' }}</p>
              </div>
            </a>
          </li>
        </ul>
        <ul class="list-unstyled mb-0 login-box" *ngIf="!inboxActive">
          <li><i class="fas fa-envelope" style="font-size: 36px;"></i></li>
          <li>
            {{'when_you_start_conversation' | language}}
          </li>
          <li *ngIf="!customer">
            <a class="a-btn" (click)="Login()">{{'sign_in' | language}}</a> /
            <a class="a-btn" (click)="Signup()">{{'register' | language}}</a>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-between align-items-center mx-2 mb-1" *ngIf="!!inboxActive">
        <textarea
          name="message-textarea"
          id="input-msg"
          rows="1"
          class="form-control"
          maxlength="4000"
          [formControl]="inputForm"
          (keydown)="typesTextArea($event)"
          (focus)="focusTextArea()"
        ></textarea>
        <i class="fas fa-paper-plane px-2 i-send" tabindex="-1" (click)="sendReply()"></i>
      </div>
    </div>
  </div>
</div>

<!-- Less 768 Mobile View -->
<div class="mbs-container">
  <a class="btn-openbox" id="mbsBtn" (click)="openMobileBoxCont()">
    <i class="fas fa-comments"></i> Message
    <small class="btn-count-unread" [hidden]="!unreadCount">{{ unreadCount > 9 ? '9+' : unreadCount }}</small>
  </a>

  <div class="box-container animated faster" id="mbsBox">
    <div class="customer-list">
      <h3 class="customer-header">
        <i class="fas fa-comments"></i> Message
        <i class="close fas fa-times" (click)="closeMobileBoxCont()"></i>
      </h3>
      <ul class="list-inbox list-unstyled">
        <ng-container *ngFor="let inb of inboxs">
          <li class="list-item" (click)="setActMsger(inb)">
            <a class="inbox-item">
              <ng-container *ngIf="!!inb.CustomerId">
                <img class="img-cus" *ngIf="inb.CustomerImg" [src]="content + inb.CustomerImg" alt="img" />
                <img class="img-cus" *ngIf="!inb.CustomerImg" [src]="noUserImg" alt="img" />
              </ng-container>
              <ng-container *ngIf="!inb.CustomerId">
                <img class="img-cus" src="../../../../assets/icons/technology.png" alt="img" />
              </ng-container>
              <div class="inbox-object">
                <p class="inbox-name">{{ inb.CustomerUsername || 'ADMIN' }}</p>
                <p class="inbox-lastmsg">
                  <small>{{ inb.LastMessage }}</small>
                </p>
              </div>
              <div class="count-unread" *ngIf="!!inb.Unread">
                {{ inb.Unread > 9 ? '9+' : inb.Unread }}
              </div>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="massage-list" [class.show]="inboxActive">
      <h3 class="massage-header">
        <i class="fas fa-chevron-left" (click)="clearActMsger()"></i>
        <ng-container *ngIf="!!inboxActive?.CustomerId; else adminTemp">
          <img
            class="message-img-header"
            *ngIf="!!inboxActive?.CustomerImg"
            [src]="content + inboxActive?.CustomerImg"
            alt=""
          />
          <img class="message-img-header" *ngIf="!inboxActive?.CustomerImg" [src]="noUserImg" alt="" />
          <span>{{ inboxActive?.CustomerUsername }}</span>
        </ng-container>
        <ng-template #adminTemp>
          <img class="message-img-header" src="../../../../assets/icons/technology.png" alt="" />
          <span *ngIf="!inboxActive?.CustomerId">Admin</span>
        </ng-template>
        <i class="close fas fa-times" (click)="closeMobileBoxCont()"></i>
      </h3>
      <div class="reply-scroller" id="scroll-mb">
        <ul class="list-unstyled list-reply animated fadeIn faster" *ngIf="!!inboxActive">
          <li
            class="list-item"
            *ngFor="let rep of replies"
            [class.right]="rep.CustomerSenderId === customer?.CustomerId"
          >
            <small class="reply-newday" *ngIf="rep.newDay">{{ rep.CreateAt | date: 'd MMM' }}</small>
            <ng-container *ngIf="rep.MessageData">
              <a class="auc-compo">
                <div class="auc-image">
                  <img width="50px" [src]="content + rep.MessageData.PreviewImage[0].MedPath" alt="">
                </div>
                <div class="auc-text ml-2">
                  <p class="name">{{ rep.MessageData.AucName }}</p>
                  <p class="price" *ngIf="rep.MessageData.StartPrice">{{ rep.MessageData.StartPrice }}</p>
                  <p class="price" *ngIf="rep.MessageData.WinnerAuctionPrice">{{ rep.MessageData.WinnerAuctionPrice }}</p>
                </div>
                <div class="ml-auto">
                  <button class="btn btn-primary" (click)="nextAuction(rep.MessageData)">{{'more' | language}}</button>
                </div>
              </a>
            </ng-container>
            <a class="reply-item" [class.mb-3]="rep.newTime">
              <p class="reply-text" *ngIf="!rep.MessageData">{{ rep.Message }}<span class="tringle"></span></p>
              <div>
                <p class="reply-time px-1" *ngIf="rep.newTime">{{ rep.CreateAt | date: 'HH:mm' }}</p>
                <p class="reader">{{ rep.Read ? '✓'+('read' | language) : '' }}</p>
              </div>
            </a>
          </li>
        </ul>
        <ul class="list-unstyled mb-0 login-box" *ngIf="!inboxActive">
          <li><i class="fas fa-envelope" style="font-size: 36px;"></i></li>
          <li>
            {{'when_you_start_conversation' | language}}
          </li>
          <li *ngIf="!customer">
            <a class="a-btn" (click)="Login()">{{'sign_in' | language}}</a> /
            <a class="a-btn" (click)="Signup()">{{'register' | language}}</a>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-between align-items-center mx-2 mb-1" *ngIf="!!inboxActive">
        <textarea
          name="message-textarea"
          id="input-msg-mb"
          rows="1"
          class="form-control"
          maxlength="100"
          [formControl]="inputForm"
          (keydown)="typesTextArea($event)"
          (focus)="focusTextArea()"
        ></textarea>
        <i class="fas fa-paper-plane px-2 i-send" tabindex="-1" (click)="sendReply()"></i>
      </div>
    </div>
  </div>
</div>
