import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { AdminMessagerComponent } from './components/admin-messager/admin-messager.component';
import { BestNavbarComponent } from './components/best-navbar/best-navbar.component';
import { BestFooterComponent } from './components/best-footer/best-footer.component';
import { TruncatePipe } from '../shared/pipes/truncate.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    AdminMessagerComponent,
    BestNavbarComponent,
    BestFooterComponent,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    AdminMessagerComponent,
    BestNavbarComponent,
    BestFooterComponent
  ]
})
export class CoreModule { }
